<template>
    <div class="max">

        <div class="right">
            <div class="right_top">
                <el-row class="dell">
                    <el-button type="info" @click="addbutton">添加</el-button>
                    <el-button :disabled="bianji" type="info" @click="edit">编辑</el-button>
                    <el-button :disabled="xiazai" type="danger" @click="moredel">删除</el-button>
                    <el-button type="warning" @click="reset">重置</el-button>
                    <el-form :inline="true" :model="formInline" class="demo-form-inline">
                        <el-form-item label="组名">
                            <el-input v-model="formInline.user" placeholder="输入组名搜索"></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="onSubmit">查询</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </div>
            <el-dialog :title="designation" :visible.sync="dialogFormVisible">
                <el-form :model="form">
                    <el-form-item label="组名" label-width="120px">
                        <el-input v-model="form.name" autocomplete="off" ref="area" style="width: 100%;"></el-input>
                    </el-form-item>
                    <el-form-item label="用户" label-width="120px">
                        <el-select v-model="form.region" multiple placeholder="请选择用户" style="width: 100%;">
                            <el-option v-for="(value, index) in adminlist" :key="index" :label="value.name"
                                :value="value.val"></el-option>
                        </el-select>
                    </el-form-item>

                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="adddata('ruleForm')">确 定</el-button>
                </div>
            </el-dialog>
            <!-- 表单数据 -->
            <div class="biaodan">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column prop="id" label="ID" width="120" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="name" label="组名" width="120" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="member" label="组内成员" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column label="创建时间" show-overflow-tooltip>
                        <template slot-scope="scope">{{ scope.row.date }}</template>
                    </el-table-column>
                    <el-table-column label="更新时间" show-overflow-tooltip>
                        <template slot-scope="scope">{{ scope.row.update }}</template>
                    </el-table-column>
                    <el-table-column prop="operate" label="操作" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-button type="info" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                            <el-button type="danger" @click="remove(scope.$index, scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 页码 -->
            <div class="block">
                <el-pagination background layout="total, prev, pager, next, sizes" :total="sumye"
                    :page-sizes="[5, 10, 20, 50, 100]" :page-size="Barpage" @size-change="dangqiang" @current-change="yema">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

import { getGroup, addGroup, editGroup, deleteGroup, getAdmin } from "@/api/jiekou"



export default {
    name: 'XianmuFkmd',

    data() {
        return {
            xiazai: true,  //控制删除是否可以触发
            bianji: true,//控制编辑是否可以触发
            dialogFormVisible: false,   //添加弹窗
            form: {
                name: '',
                region: []
            },
            designation: "添加",
            // 添加弹窗中的管理员列表
            adminlist: [

            ],
            // 搜索
            formInline: {
                user: ''
            },
            //用户表单
            tableData: [],
            multipleSelection: [],
            currentPage4: 4,
            sumye: 0,  //总页数
            Barpage: 5,   //条页  选中的页码条数
            pagination: {},  //存放筛选后的参数  用于页码
        };
    },

    mounted() {
        this.getlie()
        this.dangqiang()
        this.conservator()
    },

    methods: {
        // 管理员名单
        async conservator() {
            let _this = this
            let res = await getAdmin()
            // console.log(res.data.data)
            res.data.data.data.forEach(val => {
                let arr = {}
                arr.name = val.username
                arr.val = val.id
                _this.adminlist.push(arr)
            })
        },
        // 点击添加按钮出现弹窗
        async addbutton() {
            let _this = this
            this.dialogFormVisible = true
            this.form = {
                name: '',
                region: []
            }
        },
        // 添加小组
        async adddata() {
            if (this.form.name && this.form.region.length > 0) {
                if (this.designation == "添加") {
                    this.dialogFormVisible = false
                    let arr = await addGroup({ group_name: this.form.name, users: this.form.region.toString() })
                    alert(arr.data.msg);
                    this.getlie()
                } else {
                    this.dialogFormVisible = false
                    let arr = await editGroup({ id: this.form.id, group_name: this.form.name, users: this.form.region.toString() })
                    alert(arr.data.msg);
                    this.getlie()
                }
            } else {
                alert('框中不能为空');
            }

        },
        // 编辑单个小组
        handleEdit(index, value) {


            let _this = this
            // console.log(index, value)
            this.designation = "编辑"
            this.dialogFormVisible = true
            this.form.name = value.name
            // console.log(value.member.split(","), value.username)
            this.form.region = value.member.split(",").map(Number)
            this.form.id = value.id
        },
        edit() {
            // console.log(this.multipleSelection)
            this.designation = "编辑"
            this.dialogFormVisible = true
            this.form.name = this.multipleSelection[0].name
            // console.log(this.multipleSelection[0].member.split(","))
            this.form.region = this.multipleSelection[0].member.split(",")
            this.form.id = this.multipleSelection[0].id
        },
        // 单个删除小组
        async remove(index, value) {
            let res = await deleteGroup({ id: value.id })
            alert(res.data.msg);
            this.getlie()
        },
        // 选中多个进行删除
        async moredel() {
            // console.log(this.multipleSelection)
            let array = []
            this.multipleSelection.forEach(val => {
                array.push(val.id)
            })
            let res = await deleteGroup({ id: array.toString() })
            alert(res.data.msg)
            this.getlie()
        },
         // 重置按钮
         reset(){
            this.formInline.user=""
            this.getlie()
            this.$message({
          message: '重置成功',
          type: 'success'
        });
        },
        // 点击搜索按钮
        async onSubmit() {
            let _this = this
            if (this.formInline.user) {
                let res = await getGroup({ group_name: this.formInline.user })
                _this.pagination = {
                    group_name: this.formInline.user
                }
                if (res.data.code == 200) {
                    _this.tableData= []
                    this.sumye = res.data.data.count
                    res.data.data.data.forEach(val => {
                        let obj = {}
                        obj.id = val.id
                        obj.name = val.group_name
                        obj.member = val.users
                        obj.username = val.username
                        obj.date = _this.timestampToTime(val.createtime)
                        obj.update = _this.timestampToTime(val.updatetime)

                        _this.tableData.push(obj)
                    })
                }
                // console.log(res)
            } else {
                _this.getlie()
                _this.pagination = {}
            }
        },
        //表单选中
        handleSelectionChange(val) {
            this.multipleSelection = val;
            val.length == 0 ? this.xiazai = true : this.xiazai = false
            if (val.length == 1) {
                this.bianji = false
            } else {
                this.bianji = true
            }
        },
        // 小组名单列表
        async getlie() {
            let _this = this
            let res = await getGroup()
            // console.log(res.data)
            if (res.data.code==-1) {
                alert("你的用户失效，请重新登录")
                this.$router.replace("/login");
            }else{
                _this.sumye = res.data.data.count
                _this.tableData.length = []
                res.data.data.data.forEach(val => {
                    let obj = {}
                    obj.id = val.id
                    obj.name = val.group_name
                    obj.member = val.users
                    obj.username = val.username
                    obj.date = _this.timestampToTime(val.createtime)
                    obj.update = _this.timestampToTime(val.updatetime)

                    _this.tableData.push(obj)
                });
                // console.log(_this.tableData)
            }
        },

        // 页码  每页条数
        async dangqiang(value) {
            let _this = this
            this.Barpage = value
            _this.pagination.limit = value
            let res = await getGroup(_this.pagination)
            // console.log(res.data.data.data)
            _this.tableData.length = []
            res.data.data.data.forEach(val => {
                let obj = {}
                obj.id = val.id
                obj.name = val.group_name
                obj.member = val.users
                obj.username = val.username
                obj.date = _this.timestampToTime(val.createtime)
                obj.update = _this.timestampToTime(val.updatetime)
                _this.tableData.push(obj)
            })

        },
        async yema(value) {
            let _this = this
            _this.pagination.limit = this.Barpage
            _this.pagination.page = value
            let res = await getGroup(_this.pagination)
            // console.log(res.data.data.data)
            _this.tableData.length = []
            res.data.data.data.forEach(val => {
                let obj = {}
                obj.id = val.id
                obj.name = val.group_name
                obj.member = val.users
                obj.username = val.username
                obj.date = _this.timestampToTime(val.createtime)
                obj.update = _this.timestampToTime(val.updatetime)
                _this.tableData.push(obj)

            })

        },


        //将时间戳转换成日期格式
        timestampToTime(timestamp) {
            // 时间戳为10位需*1000，时间戳为13位不需乘1000
            var date = new Date(timestamp * 1000);
            var Y = date.getFullYear() + "-";
            var M =
                (date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1) + "-";
            var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
            var h = date.getHours() + ":";
            var m = date.getMinutes() + ":";
            var s = date.getSeconds();
            return Y + M + D + h + m + s;
        },
        // 中国标准时间转换成日期格式
        timeriqi(time) {
            var date = new Date(time);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            var minute = date.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            return time = y + '-' + m + '-' + d;
        }
    },
};
</script>

<style lang="less" scoped>
.max {
    display: flex;
}


.right {
    height: calc(100vh - 70px);
    overflow-y: scroll;
    width: 100%;
    padding: 10px 10px 0 15px;
}

.right .right_top {
    width: 100%;
    display: flex;
}

.right .right_top .xianshi {
    padding: 5px;
    border: 1px solid #d5d8db;
    color: #28334B;
    font-size: 12px;
    border-radius: 0px;
    margin-right: 10px;
}

.right .right_top .xianshi:hover {
    color: #1890ff;
    border: 1px solid #1890ff;
}


::v-deep .el-dialog__body .el-checkbox-group {
    display: flex;
}

::v-deep .el-dialog__body ul:nth-child(1) {
    padding: 0px;
}

::v-deep .el-dialog__body ul li {
    list-style: none;
    margin-bottom: 10px;
}

.dell {
    display: flex;
    align-items: center;
}

.dell ::v-deep .el-button {
    padding: 5px 10px;
    border-radius: 0px;

}

.dell .el-form {
    margin-left: 10px;
}

.dell .el-form .el-form-item {
    margin-bottom: 0px;
}

::v-deep .el-date-editor .el-input__icon:nth-child(1) {
    display: none;
}

.biaodan {
    margin: 10px 0;
}

.biaodan ::v-deep .el-table .el-table__header-wrapper .el-table__header .has-gutter tr th {
    background: #F4F7FB;
    color: #8DA2B5;
}

.biaotou1 {
    position: absolute;
    left: 66px;
    top: 4px;
}

.biaotou {
    position: absolute;
    left: 52px;
    top: 4px;
}

.block {
    display: flex;
    justify-content: end;
}

.block ::v-deep .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin-top: 8px;
}

.block ::v-deep .el-pagination__sizes {
    margin-right: 0;
}

.block ::v-deep .el-pagination__sizes .el-input {
    margin-right: 0;
}

.block ::v-deep .el-pager li,
.block ::v-deep .el-pagination button {
    border: 1px solid #ebebeb;
    background-color: #fff;
    font-weight: initial;
    margin: 0 3px;
}
</style>